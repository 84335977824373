import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { useDispatch } from '../hooks/useDispatch';
import { EventTypes } from '../types/EventTypes';
import { LocalLogin } from './LocalLogin';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { myProfile } from '../store/gql/__generated__/myProfile';
import { myProfileQuery } from '../store/gql/myProfile.query';
import { CONFIG, LoginMethod } from '../config';
import { CognitoLogin } from './CognitoLogin';
import { cognitoAuthSubject } from '../store/auth/cognitoAuthSubject';

export const MyProfile: React.FC = (props) => {
  const authState = useObservable(() => cognitoAuthSubject)
  const dispatch = useDispatch()
  const myProfile = useQuery<myProfile>(myProfileQuery, {skip: !authState?.isLogin})
  if (!authState || authState.loading) return null
  if (!authState?.isLogin && CONFIG.loginWith === LoginMethod.local) return <LocalLogin/>
  if (!authState?.isLogin && CONFIG.loginWith === LoginMethod.cognito) return <CognitoLogin>button</CognitoLogin>
  if (myProfile.loading || !myProfile.data) return null

  const {userId} = authState

  return <div className="mt-10 w-full">
    <ul className="menu p-4 bg-base-100">
      <li className={'disabled'}>
        <a href={"/"}>UserName: {userId}</a>
      </li>
      <li className={'disabled'}>
        <a href={"/"}>DisplayName: {myProfile.data.myProfile.displayName}</a>
      </li>
      <li>
        <Link to={'/create'}>Create Post</Link>
      </li>
      <li>
        <a className={''}
           onClick={() => dispatch({type: EventTypes.logout})}>Logout</a>
      </li>
    </ul>
  </div>
}
