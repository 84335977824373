import { gql } from '@apollo/client';

export const myProfileQuery = gql`query myProfile {
  myProfile {
    id
    email
    username
    displayName
    created
    accounts {
      id
      type
      holdings {
        tokenId
        balance
        updated
      }
    }
  }
}`
