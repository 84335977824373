import {BehaviorSubject, Subject} from "rxjs";
import {baseEventSubject} from "./baseEventSubject";
import {pickEvent} from "./utils/pickEvent";
import {EventTypes} from "../types/EventTypes";
import * as Rx from "rxjs/operators";

export const fileUploadSubject = new Subject<File>();
export const titleSubject = new Subject<string>();
export const loadingSubject = new BehaviorSubject<boolean>(false);

baseEventSubject
  .pipe(
    pickEvent(EventTypes.createPostFileUpload),
    Rx.map(({payload: file}) => file),
  ).subscribe(fileUploadSubject);

baseEventSubject
  .pipe(
    pickEvent(EventTypes.createPostTitleChanged),
    Rx.map(({payload: title}) => title),
  ).subscribe(titleSubject);

baseEventSubject
  .pipe(
    pickEvent(EventTypes.createPostSubmitting),
    Rx.mapTo(true),
  ).subscribe(loadingSubject);

baseEventSubject
  .pipe(
    pickEvent(EventTypes.createPostSubmittingFinished),
    Rx.mapTo(false),
  ).subscribe(loadingSubject);
