import {
  BehaviorSubject,
  distinctUntilChanged,
  interval,
  map,
  merge,
  scan,
} from "rxjs";
import {baseEventSubject, dispatch} from "./baseEventSubject";
import {EventTypes} from "../types/EventTypes";
import {pickEvent} from "./utils/pickEvent";
import {nanoid} from "nanoid";

export enum AlertType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning'
}

interface IAlert {
  id?: string;
  type: AlertType;
  message: string;
  timeout?: number;
}

export const sendAlert = (alert: IAlert | string) => {
  const id = nanoid()
  dispatch({
    type: EventTypes.sendAlert,
    payload: (alert instanceof String) ? {
      id,
      type: AlertType.Info,
      message: alert,
      timeout: 3000
    } : {...alert as IAlert, id}
  })
};

export const globalAlertSubject = new BehaviorSubject<IAlert[]>([]);

merge(
  interval(300),
  baseEventSubject
    .pipe(
      pickEvent(EventTypes.sendAlert),
      map(event => event.payload as IAlert),
    ),
  baseEventSubject
    .pipe(
      pickEvent(EventTypes.closeAlert),
      map(event => event.payload as string), // id
    )
).pipe(
  scan((acc: IAlert[], alertOrSignalOrId) => {
      if(typeof alertOrSignalOrId === 'number') {
        // remove timeout alerts
        return acc.filter(alert => alert.timeout! > +new Date())
      } else if (typeof alertOrSignalOrId === 'string') {
        // remove alert by id
        return acc.filter(alert => alert.id !== alertOrSignalOrId)
      } else {
        // add alert
        return [...acc, {
          ...alertOrSignalOrId,
          timeout: +new Date() + (alertOrSignalOrId.timeout || 2000)
        }]
      }
  }
    , [] as IAlert[]),
  distinctUntilChanged((a, b) => a.length === b.length && a.map(alert => alert.id).join('') === b.map(alert => alert.id).join(''))
)
  .subscribe(globalAlertSubject)

