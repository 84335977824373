import { ApolloClient, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { CONFIG } from '../../config';

const link = new BatchHttpLink({
  uri: CONFIG.baseURL + '/api/graphql',
  batchMax: 5, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
  credentials: 'include',
});


export const apolloClient = new ApolloClient({
  link,
  credentials: 'include',
  cache: new InMemoryCache(
    {
    typePolicies: {
      Query: {
        fields: {
          posts: {
                      // any of this field's arguments.
                      keyArgs: false,
                      // Concatenate the incoming list items with
                      // the existing list items.
                      merge(existing , incoming) {
                        if (!incoming) return existing;
                        if (!existing) return incoming;
                        const { edges, ...rest } = incoming;
                        let result = rest;
                        result.edges = [...existing.edges, ...edges];
                        return result;
                      },
          }
        }
      }
    }
  }
  )
});

