import { cognitoUrlGenerator } from './infra/cognito/cognitoUrlGenerator';

enum Environment {
  DEV = 'dev',
  PROD = 'prod', // or say ap
  localServer = 'localServer',
}

const apCognitoDomain = 'bumo-auth.auth.ap-southeast-2.amazoncognito.com';
const apClientCognitoClientId = '2e97bm1aum0c04mkif1s7979n5';

const localDevBackend = 'http://localhost:4000';
const localDevFrontEnd = 'http://localhost:3000';

const apBackend = 'https://a4dz53ft21.execute-api.ap-southeast-2.amazonaws.com'

export enum LoginMethod {
  local = 'local',
  cognito = 'cognito'
}

const localDev = {
  baseURL: localDevBackend,
  baseToken: 'XQB',
  loginWith: LoginMethod.local,
  cognito: cognitoUrlGenerator({
    domain: apCognitoDomain,
    clientId: apClientCognitoClientId,
    redirectUri: localDevFrontEnd
  })
};

const localServe = {
  baseURL: '', // will be proxy
  baseToken: 'XQB',
  loginWith: LoginMethod.local,
  cognito: cognitoUrlGenerator({
    domain: apCognitoDomain,
    clientId: apClientCognitoClientId,
    redirectUri: localDevFrontEnd
  })
};

const apUAT = {
  baseURL: apBackend,
  baseToken: 'XQB',
  loginWith: LoginMethod.cognito,
  cognito: cognitoUrlGenerator({
    domain: apCognitoDomain,
    clientId: apClientCognitoClientId,
    redirectUri: localDevFrontEnd
  })
};

const envMap = {
  [Environment.localServer]: localServe,
  [Environment.DEV]: localDev,
  [Environment.PROD]: apUAT,
}

// @ts-ignore
console.log(envMap[process.env.REACT_APP_ENV])

// @ts-ignore
if (!(process.env.REACT_APP_ENV && envMap[process.env.REACT_APP_ENV])) {
  throw new Error(`REACT_APP_ENV (${process.env.REACT_APP_ENV}) is not defined or not supported`);
}

// @ts-ignore
export const CONFIG: typeof apUAT = envMap[process.env.REACT_APP_ENV];
