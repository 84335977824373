import axios from "axios";
import {CONFIG} from "../../config";

// create post with title and file by axios
export const uploadAttachment = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await axios.post<{ id: string, url: string }>(
    `${CONFIG.baseURL}/api/restful/attachments`,
    formData,
    {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  return response.data
};
