import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

import {
  postById_post_tokenMarketData
} from '../store/gql/__generated__/postById';

interface Props {
  tokenMarketData: postById_post_tokenMarketData[];
  width: number;
  height: number;
  margin: { top: number, right: number, left: number, bottom: number };
}
// @ts-ignore
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className='bg-base-100 text-2xs text-info'>
        <p>{`XQB : ${payload[0].value}`}</p>
        <p>{`Volume : ${payload[0].payload.volume}`}</p>
        <p>{`${new Date(payload[0].payload.timestamp).toUTCString()}`}</p>
      </div>
    );
  }

  return null;
};

export const PostChart: React.FC<Props> = ({
                                             tokenMarketData,
                                             width,
                                             height,
                                             margin
                                           }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={width}
        height={height}
        data={tokenMarketData}
        margin={margin}
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="null"/>
        <YAxis/>
        <Tooltip content={<CustomTooltip active={undefined} payload={undefined}/>}/>
        <Legend/>
        <Line type="monotone" dataKey="unitPrice" stroke="#8884d8"
              activeDot={{ r: 1 }} dot={false}/>
      </LineChart>
    </ResponsiveContainer>
  );
};



