import { gql } from '@apollo/client';

export const postByIdQuery = gql`
  query postById($id: ID!) {
    post(id: $id) {
      id
      uid
      title
      userId
      suggestedBidOrder {
        id
        unitPrice
        status
        type
      }
      suggestedOfferOrder {
        id
        unitPrice
        status
        type
      }
      tokenMarketData {
        id
        timestamp
        unitPrice
        supplyAmount
        soldAmount
        capitalValue
        tradeRecentDay
        volume
      }
      latestTokenMarketData {
        id
        timestamp
        unitPrice
        supplyAmount
        soldAmount
        capitalValue
        tradeRecentDay
      }
      attachments {
        id
        url
      }
      profile {
        id
        username
      }
    }
  }`
