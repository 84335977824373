import { gql } from '@apollo/client';

export const AllPostsQuery = gql`
  query AllPosts($pagination: PaginationInputType, $orderBy: PostOrderBy) {
    posts(pagination: $pagination, orderBy: $orderBy) {
      edges {
        node {
          id
          uid
          title
          userId
          suggestedBidOrder {
            id
            unitPrice
            status
            type
          }
          suggestedOfferOrder {
            id
            unitPrice
            status
            type
          }
          latestTokenMarketData {
            id
            timestamp
            unitPrice
            supplyAmount
            soldAmount
            capitalValue
            tradeRecentDay
          }
          attachments {
            id
            url
          }
          profile {
            id
            username
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
