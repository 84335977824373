import { BehaviorSubject } from 'rxjs'
import { EventTypes } from '../types/EventTypes'

export interface BaseEvent<T> {
  type: EventTypes,
  payload?: T
}

const initEvent: BaseEvent<undefined> = {type: EventTypes.init}

export const baseEventSubject = new BehaviorSubject<BaseEvent<any>>(initEvent)

export const dispatch = (event: BaseEvent<any>) => {
  console.log('☑️', event)
  baseEventSubject.next(event)
}

