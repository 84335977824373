import React from 'react';
import { CONFIG } from '../config';

export const CognitoLogin: React.FC = ()=>{
  const reDirectToCognito = ()=>{
    window.location.replace( CONFIG.cognito.login)
  }
    return (
        <div>
            <button className="btn btn-lg" onClick={reDirectToCognito}>CognitoLogin</button>
        </div>
    )
}
