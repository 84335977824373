import React from 'react'
import { Field, Form, Formik, } from 'formik'
import { useDispatch } from '../hooks/useDispatch'
import { EventTypes } from '../types/EventTypes'

export const LocalLogin: React.FC = (props) => {
  const dispatch = useDispatch()
  return (
    <div className="w-4/5 mt-20">
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={(values, actions) => {
          dispatch({type: EventTypes.login, payload: values})
          console.log({ values, actions })
          actions.setSubmitting(false)
        }}
      >
        <Form className="object-center">
          <h1 className="flex-1 mb-4">Login</h1>
          <div className="form-control">
            <label className="label" htmlFor="username">
              <span className="label-text">Username</span>
            </label>
            <Field className="input input-bordered" id="username"
                   name="username" />
          </div>
          <div className="form-control">
            <label className="label" htmlFor="password">
              <span className="label-text">Password</span>
            </label>
            <Field className="input input-bordered" id="password"
                   name="password" />
          </div>
          <button className="btn mt-4" type="submit">Submit</button>
        </Form>
      </Formik>
    </div>
  )
}
