import React from 'react';

const showPhoneWrap = process.env.REACT_APP_ENV === 'dev'

export const PhoneWrap: React.FC<React.PropsWithChildren<{}>> = (prop) => showPhoneWrap ? (
  <div className="mockup-phone ">
    <div className="camera"/>
    <div className="display relative">
      <div className="artboard phone-2 artboard-demo flex flex-column justify-between justify-items-stretch">
        {prop.children}
      </div>
    </div>
  </div>
) : <>{prop.children}</>
