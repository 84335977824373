import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { myHoldingsQuery } from '../store/gql/myHoldings.query';
import {
  myHoldings,
  myHoldings_myProfile_accountByType_holdings
} from '../store/gql/__generated__/myHoldings';
import { getPostAttachmentUrls } from './utils/GetPostAttachmentUrls';
import accounting from 'accounting';
import { CONFIG } from '../config';

const HoldingListItem = (props: { holding: myHoldings_myProfile_accountByType_holdings }) => {
  const attachmentUrls = props.holding.post && getPostAttachmentUrls(props.holding.post);
  const tokenId = props.holding.tokenId;
  const isBasedToken = tokenId === CONFIG.baseToken;

  return (
    <li className="card shadow-lg compact side bg-base-100 mx-2 mb-5">
      <Link to={`/@${tokenId}`}
            className="flex-row items-center space-x-4 card-body">
        <div>
          <div className="avatar">
            <div
              className={`rounded-full w-14 h-14  ${!isBasedToken && 'shadow'}`}>
              {attachmentUrls && attachmentUrls.length > 0 &&
                <img src={attachmentUrls[0]} alt={attachmentUrls[0]}/>}
            </div>
          </div>
        </div>
        <div>
          <h2 className="card-title">
            {accounting.formatMoney(props.holding.valuation)}
          </h2>
          <div>
            <span
              className="badge badge-outline mr-2"> {props.holding.tokenId} </span>
            {!isBasedToken && <span
              className="badge badge-outline mr-2"> {accounting.formatMoney(props.holding.valuation / props.holding.balance)}</span>}
            {!isBasedToken && <span
              className="badge badge-outline mr-2"> {accounting.formatNumber(props.holding.balance)}</span>}
          </div>
        </div>
      </Link>
    </li>
  );
};

export const MyPortfolio: React.FC = () => {
  const myHoldingsResult = useQuery<myHoldings>(myHoldingsQuery);

  if (myHoldingsResult.error) {
    return <pre>{JSON.stringify(myHoldingsResult.error, null, 2)}</pre>;
  }

  if (myHoldingsResult.loading || !myHoldingsResult.data) {
    return <div>Loading...</div>;
  }

  const orderedHolding = myHoldingsResult.data.myProfile.accountByType.holdings
    .filter(holding => holding.tokenId !== CONFIG.baseToken)
    .sort((a, b) => b.valuation - a.valuation);
  const baseTokenHolding = myHoldingsResult.data.myProfile.accountByType.holdings
    .find(holding => holding.tokenId === CONFIG.baseToken);

  return <div className="pb-8">
    <ul className="flex flex-1 flex-col mt-4">
      {baseTokenHolding && <HoldingListItem holding={baseTokenHolding}/>}
      {orderedHolding.map(holding =>
        <HoldingListItem key={holding.tokenId} holding={holding}/>
      )}
    </ul>
  </div>;
};
