import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { PlaceOrderMutation } from '../store/gql/placeOrder.mutation';
import {
  placeOrder,
  placeOrderVariables
} from '../store/gql/__generated__/placeOrder';
import { myProfile } from '../store/gql/__generated__/myProfile';
import { myProfileQuery } from '../store/gql/myProfile.query';
import { AccountType, OrderType } from '../types/GlobalTypes';
import { postByIdQuery } from '../store/gql/postByUid.query';
import {
  postById,
  postByIdVariables
} from '../store/gql/__generated__/postById';

export const PlaceOrder: React.FC<{userId: string, uid: number, cancel: () => void}> = ({uid, userId, cancel}) => {
  const myProfileResult = useQuery<myProfile>(myProfileQuery)

  const [placeOrderFunc, placeOrderResult] = useMutation<placeOrder, placeOrderVariables>(PlaceOrderMutation)
  console.log('placeOrderResult', placeOrderResult)

  const postByUidResult = useQuery<postById, postByIdVariables>(postByIdQuery, {
    variables: {
      id: `${userId}/${uid}`
    }
  })
  console.log(postByUidResult.data?.post)

  // const myOrdersResult = useQuery<myOrders, myOrdersVariables>(myOrdersQuery)

  const tokenId = `${userId}/${uid}`

  const foundPrimaryAccountId = myProfileResult.data?.myProfile.accounts.find(account => account.type === AccountType.primary)?.id

  if (!foundPrimaryAccountId || postByUidResult.loading || myProfileResult.loading) return <h1>Loading</h1>
  console.log('foundPrimaryAccountId', foundPrimaryAccountId)

  return (
    <div className="w-full">
      <Formik
        initialValues={{
          amount: 1,
          type: OrderType.BID,
          unitPrice: postByUidResult.data?.post?.suggestedBidOrder?.unitPrice || '-',
        }}
        validationSchema={Yup.object().shape({
          amount: Yup
            .number()
            .min(1, 'Amount must be greater than 1')
            .max(1000, 'Amount must be less than 1000')
            .integer()
            .required(),
          type: Yup.string()
            .oneOf([OrderType.OFFER, OrderType.BID], 'Must be either offer or bid')
            .required(),
          unitPrice: Yup
            .number()
            .min(0.01, 'Unit price must be greater than 0.01')
            .required(),
        })}
        onSubmit={
          async (values, {setSubmitting, resetForm}) => {
            const result = await placeOrderFunc({
              variables: {
                input: {
                  accountId: foundPrimaryAccountId,
                  amount: +values.amount,
                  tokenId,
                  type: values.type,
                  unitPrice: +values.unitPrice,
                }
              }
            })
            console.log('result', result)
            setSubmitting(false)
            resetForm()
          }
        }>
        {
          formikProps => (
            <Form className="object-center">
              <div className="btn-group">
                <div
                  onClick={() => {
                    formikProps.setFieldValue('type', OrderType.BID)
                    formikProps.setFieldValue('unitPrice', postByUidResult.data?.post?.suggestedBidOrder?.unitPrice || '-')
                    formikProps.setFieldTouched('unitPrice', false)
                  }}
                  className={`btn btn-sm ${formikProps.values.type === OrderType.BID && 'btn-active'}`}
                >Bid
                </div>
                <div
                  onClick={() => {
                    formikProps.setFieldValue('type', OrderType.OFFER)
                    formikProps.setFieldValue('unitPrice', postByUidResult.data?.post?.suggestedOfferOrder?.unitPrice || '-')
                    formikProps.setFieldTouched('unitPrice', false)
                  }}
                  className={`btn btn-sm ${formikProps.values.type === OrderType.OFFER && 'btn-active'}`}
                >Offer
                </div>
              </div>
              {formikProps.errors.type &&
              <div className="text-danger">{formikProps.errors.type}</div>}
              <div className="form-control mt-4">
                <label className="input-group input-group-sm" htmlFor="unitPrice">
                  <span className="w-20">Price</span>
                  <Field className="input input-bordered input-sm" id="unitPrice" name="unitPrice"/>
                </label>
              </div>
              {formikProps.errors.unitPrice && <div className="text-danger">{formikProps.errors.unitPrice}</div>}
              <div className="form-control mt-4">
                <label className="input-group input-group-sm" htmlFor="amount">
                  <span className="w-20">Amount</span>
                  <Field className="input input-bordered input-sm" id="amount" name="amount"/>
                </label>
              </div>
              {formikProps.errors.amount && <div className="text-danger">{formikProps.errors.amount}</div>}
              <div className="w-full flex justify-end">
                <button type={'reset'}
                        onClick={() => {formikProps.resetForm(); cancel()}}
                        className={`btn btn-link btn-sm mt-4 ${formikProps.isSubmitting && 'btn-loading'}`}>
                  Cancel
                </button>
                <button disabled={!formikProps.isValid} type={'submit'}
                        className={`btn btn-primary btn-sm mt-4 ${formikProps.isSubmitting && 'btn-loading'}`}>
                  Confirm
                </button>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
  )
}
