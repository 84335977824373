import { apolloClient } from '../../infra/apollo/apollo.client';
import { CreatePostMutation } from '../gql/CreatePostMutation';

export const createPost = async (title: string, attachmentId: string) => {
  return await apolloClient.mutate({
    mutation: CreatePostMutation,
    refetchQueries: ['AllPosts'],
    variables: {
      input: {
        title: title,
        attachmentIds: [attachmentId],
      }
    }
  })
}
