import React from 'react';
import {useObservable} from "rxjs-hooks";
import {globalAlertSubject} from "../store/globalAlertSubject";
import {useDispatch} from "../hooks/useDispatch";
import {EventTypes} from "../types/EventTypes";
import {XIcon} from "@heroicons/react/solid";

export const GlobalAlert: React.FC = () => {
  const alerts = useObservable(() => globalAlertSubject, globalAlertSubject.getValue())
  const dispatch = useDispatch()
  const hasAlerts = alerts.length > 0
  return (
    <div
      className={`absolute top-4 left-0 w-full flex flex-col bg-base-100 z-50 ${hasAlerts ? '' : 'hidden'}`}>
      {alerts.map(alert => (
        <div onClick={() => dispatch({
          type: EventTypes.closeAlert,
          payload: alert.id
        })} key={alert.id}
             className={`alert cursor-pointer my-1 mx-4 alert-${alert.type}`}>
          <div className="flex-1">
            <label className="mx-3">
              {alert.message}
            </label>
          </div>
          <div className="flex-none">
            <XIcon className={'w-4 h-4'}/>
          </div>
        </div>
      ))}
    </div>
  )
}
