/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.


//==============================================================
// START Enums and Input Objects
//==============================================================


export enum AccountType {
  benefit = "benefit",
  primary = "primary",
}

export enum OrderStatus {
  CANCELLED = "CANCELLED",
  FINISHED = "FINISHED",
  MATCHING = "MATCHING",
}

export enum OrderType {
  BID = "BID",
  OFFER = "OFFER",
}

/**
 * The basic directions
 */
export enum PostOrderBy {
  all = "all",
  capitalValue = "capitalValue",
  currentPrice = "currentPrice",
  soldAmount = "soldAmount",
  tradeVolumeRecentDay = "tradeVolumeRecentDay",
  tradeVolumeRecentMonth = "tradeVolumeRecentMonth",
  tradeVolumeRecentWeek = "tradeVolumeRecentWeek",
}

export enum PostStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
}

export interface OrderPlaceRequestedInput {
  accountId: string;
  amount: number;
  tokenId: string;
  type: OrderType;
  unitPrice: number;
}

export interface PaginationInputType {
  limit: number;
  offset: number;
}

export interface PostCreatedInput {
  attachmentIds: string[];
  title: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

