import {
  AllPosts_posts_edges_node
} from '../../store/gql/__generated__/AllPosts';
import { CONFIG } from '../../config';
import { postById_post } from '../../store/gql/__generated__/postById';
import {
  myOrders_myProfile_orders_edges_node_post
} from '../../store/gql/__generated__/myOrders';

export const getPostAttachmentUrls = (post: AllPosts_posts_edges_node | postById_post | myOrders_myProfile_orders_edges_node_post) => {
  return post.attachments
    ?.map(attachment => attachment.url)
    .filter(url => !!url)
    .map(url => {
      if (url.startsWith('http')) return url;
      else return `${CONFIG.baseURL}/uploads/${url}`;
    }) as string[];
}
