import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { myOrdersQuery } from '../store/gql/myOrders.query';
import {
  myOrders,
  myOrders_myProfile_orders_edges_node,
  myOrdersVariables
} from '../store/gql/__generated__/myOrders';
import { getPostAttachmentUrls } from './utils/GetPostAttachmentUrls';
import { OrderStatus, OrderType } from '../types/GlobalTypes';
import { Link } from 'react-router-dom';

const OrderListItem = (props: { order: myOrders_myProfile_orders_edges_node }) => {
  const attachmentUrls = getPostAttachmentUrls(props.order.post);
  const tokenId = props.order.post.id;
  return (
    <li className="card shadow-lg compact side bg-base-100 mx-2 mb-5">
      <Link to={`/@${tokenId}`}
            className="flex-row items-center space-x-4 card-body">
        <div>
          <div className="avatar">
            <div className="rounded-full w-14 h-14 shadow">
              {attachmentUrls.length > 0 && <img src={attachmentUrls[0]}/>}
            </div>
          </div>
        </div>
        <div><h2 className="card-title">${props.order.unitPrice} <small
          className="text-primary">{props.order.amountLeft}/{props.order.amount}</small>
        </h2>
          <p className="text-base-content text-opacity-40">
            <span
              className="badge badge-sm badge-outline mr-1">#{props.order.id.substr(-5, 5)}</span>
            <span
              className={`badge badge-sm badge-outline mr-1 ${props.order.type === OrderType.OFFER ? 'badge-accent' : 'badge-secondary'}`}>{props.order.type}</span>
          </p>
        </div>
      </Link>
    </li>
  );
};

const OrderFilter: React.FC<{
  activatedTab: OrderStatus,
  setActivatedTab: React.Dispatch<React.SetStateAction<OrderStatus>>
}> = ({
        activatedTab,
        setActivatedTab
      }) => {
  return <div className="mt-6">
    <div className="tabs tabs-boxed justify-center">
      <a onClick={() => setActivatedTab(OrderStatus.MATCHING)}
         className={`tab ${activatedTab === OrderStatus.MATCHING && 'tab-active'}`}>Matching</a>
      <a onClick={() => setActivatedTab(OrderStatus.FINISHED)}
         className={`tab ${activatedTab === OrderStatus.FINISHED && 'tab-active'}`}>Finished</a>
      <a onClick={() => setActivatedTab(OrderStatus.CANCELLED)}
         className={`tab ${activatedTab === OrderStatus.CANCELLED && 'tab-active'}`}>Canceled</a>
    </div>
  </div>;
};

export const MyOrders: React.FC = () => {
  const myOrdersResult = useQuery<myOrders, myOrdersVariables>(myOrdersQuery);
  const [activatedTab, setActivatedTab] = useState<OrderStatus>(OrderStatus.MATCHING);
  if (myOrdersResult.error) return <pre>{JSON.stringify(myOrdersResult.error, null, 2)}</pre>;
  if (myOrdersResult.loading || !myOrdersResult.data) return <div>Loading...</div>;
  const orders = myOrdersResult.data.myProfile.orders.edges.filter(order => order.node.status === activatedTab);

  return (
    <div className="max-h-full h-full w-full overflow-auto relative">
      <div className="flex-shrink">
        <OrderFilter activatedTab={activatedTab}
                     setActivatedTab={setActivatedTab}/>
      </div>
      <ul className="flex flex-1 flex-col mt-4">
        {orders.map(({ node: order }) => (
          <OrderListItem key={order.id} order={order}/>
        ))}
      </ul>
    </div>
  );
};
