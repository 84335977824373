import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { postByIdQuery } from '../store/gql/postByUid.query';
import { getPostAttachmentUrls } from './utils/GetPostAttachmentUrls';
import { ChevronLeftIcon } from '@heroicons/react/outline';

import {
  postById,
  postByIdVariables
} from '../store/gql/__generated__/postById';
import { PlaceOrder } from './PlaceOrder';
import { PostChart } from './PostChart';

export const PostDetail = () => {
  const { userId, uid } = useParams();
  const id = `${userId}/${uid}`;
  const navigate = useNavigate();
  const {
    data,
    loading,
    error
  } = useQuery<postById, postByIdVariables>(postByIdQuery, { variables: { id } });
  // react state: isPlaceOrderOpen
  const [isPlaceOrderOpen, setIsPlaceOrderOpen] = useState(false);
  if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
  if (!data || !data.post || loading) return null;
  const { post } = data;
  return (
    <div className="flex flex-col h-screen h-full w-full">
      <div className="fixed left-0 top-0 p-5">
        <ChevronLeftIcon
          className={'cursor-pointer w-6 h-6 stroke-current text-white drop-shadow'}
          onClick={() => navigate(-1)}/>
      </div>
      <div
        className="flex flex-col items-center justify-center h-5/6 min-h-5/6 bg-neutral">
        {
          getPostAttachmentUrls(post).map((url, index) => (
            // tailwind image
            <figure style={{
              backgroundImage: `url(${url})`
            }}
                    className="h-full w-full overflow-hidden shadow-lg bg-contain bg-no-repeat bg-center"
                    key={index}>
            </figure>
          ))
        }
      </div>
      <div className="text-center">
        <p className="font-bold">{data.post?.title}</p>
      </div>
      <div
        className={`flex flex-col items-center justify-center ${isPlaceOrderOpen ? 'hidden' : ''}`}>
        <div className="text-center">
          <button className="btn btn-link"
                  onClick={() => setIsPlaceOrderOpen(!isPlaceOrderOpen)}>
            Place order
          </button>
        </div>
      </div>
      <div
        className={`flex flex-col items-center justify-center ${isPlaceOrderOpen ? '' : 'hidden'} p-4`}>
        <PlaceOrder userId={data.post.userId} uid={data.post.uid}
                    cancel={() => setIsPlaceOrderOpen(false)}/>
      </div>
      <div className="text-center text-primary-focus">MARKET PRICE</div>
      {data.post.tokenMarketData.length === 0 ?
        <div className="text-secondary">***There is on market price
          yet***</div> :
        <PostChart
          tokenMarketData={[...data.post.tokenMarketData].reverse()}
          width={500}
          height={500}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5
          }
          }
        />}
    </div>
  );
};
