import gql from 'graphql-tag';

export const PlaceOrderMutation = gql`
  mutation placeOrder($input: OrderPlaceRequestedInput!) {
    placeOrder(input: $input) {
      id
      tokenId
      status
      created
      updated
    }
  }
`;
