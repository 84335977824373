import { gql } from '@apollo/client';

export const myOrdersQuery = gql`
  query myOrders(
    $tokenId: String
    $status: OrderStatus
    $type: OrderType
    $pagination: PaginationInputType
  ) {
    myProfile {
      id
      username
      orders(
        tokenId: $tokenId
        status: $status
        type: $type
        pagination: $pagination
      ) {
        edges {
          cursor
          node {
            id
            userId
            accountId
            status
            type
            unitPrice
            amount
            amountLeft
            lastMatched
            created
            updated
            post {
              id
              title
              status
              created
              updated
              attachments {
                id
                url
              }
              profile {
                id
                username
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
`;
