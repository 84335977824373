export enum EventTypes {
  init = 'init',
  login = 'login',
  logout = 'logout',
  cognitoLogin = 'cognitoLogin',
  createPostFileUpload = 'createPostFileUpload',
  createPostTitleChanged = 'createPostTitleChanged',
  createPostSubmitting = 'createPostSubmitting',
  createPostSubmittingFinished = 'createPostSubmittingFinished',
  sendAlert = 'sendAlert',
  closeAlert = 'closeAlert',
  // createPostSubmit = 'createPostSubmit',
}
