import {gql} from "@apollo/client";

export const CreatePostMutation = gql`
  mutation CreatePost($input: PostCreatedInput!) {
    createPost(input: $input) {
      id
      uid
      userId
      tokenId
      title
      content
      status
      created
      updated
    }
  }
`;
