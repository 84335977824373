import React from 'react';
import {
  ChartSquareBarIcon,
  CollectionIcon,
  HomeIcon,
  UserIcon
} from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

export const BottomMenu: React.FC = () => {
  return <div className="fixed left-0 right-0 bottom-0 w-full">
    <div className={''}>
      <div
        className={'menu horizontal justify-center flex-none px-3 bg-base-100 w-full pb-2'}>
        <li>
          <Link to={'/'}>
            <HomeIcon className={'inline-block w-6 h-6 stroke-current'}/>
          </Link>
        </li>
        <li>
          <Link to={'/orders'}>
            <CollectionIcon className={'inline-block w-6 h-6 stroke-current'}/>
          </Link>
        </li>
        <li>
          <Link to={'/portfolio'}>
            <ChartSquareBarIcon
              className={"inline-block w-6 h-6 stroke-current"}/>
          </Link>
        </li>
        <li>
          <Link to={'/me'}>
            <UserIcon className={"inline-block w-6 h-6 stroke-current"}/>
          </Link>
        </li>
      </div>
    </div>
  </div>
}
