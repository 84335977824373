import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { PhoneWrap } from './components/PhoneWrap';
import { MyProfile } from './components/MyProfile';
import { useObservable } from 'rxjs-hooks';
import { BottomMenu } from './components/BottomMenu';
import { CreatePost } from './components/CreatePost';
import { GlobalAlert } from './components/GlobalAlert';
import { PostLists } from './components/PostLists';
import { PostDetail } from './components/PostDetail';
import { MyOrders } from './components/MyOrders';
import { dispatch } from './store/baseEventSubject';
import { EventTypes } from './types/EventTypes';
import { cognitoAuthSubject } from './store/auth/cognitoAuthSubject';
import { CONFIG, LoginMethod } from './config';
import { localAuthSubject } from './store/auth/localAuthSubject';
import { MyPortfolio } from './components/MyPortfolio';

const showPhoneWrap = process.env.REACT_APP_ENV === 'dev';

function App() {
  const authState = useObservable(() => CONFIG.loginWith === LoginMethod.local ? localAuthSubject : cognitoAuthSubject);

  // todo move to a hook
  useEffect(() => {
    const queryString = window.location.hash.replace('#', '?');
    const urlParams = new URLSearchParams(queryString);
    const idToken = urlParams.get('id_token');
    window.location.hash = '';
    if (idToken) {
      dispatch({
        type: EventTypes.cognitoLogin,
        payload: { jwtToken: idToken }
      });
    }
  }, []);
  if (!authState) return null;

  return (
    <div className="flex w-screen">
      <PhoneWrap>
        <BrowserRouter>
          <GlobalAlert/>
          <div className="w-full block">
            <div className="min-h-screen pb-12">
              <Routes>
                {/* home tabs */}
                <Route path="/" element={<PostLists/>}/>
                <Route path="/orders" element={<MyOrders/>}/>
                <Route path="/me" element={<MyProfile/>}/>
                <Route path="/portfolio" element={<MyPortfolio/>}/>

                {/* sub pages */}
                <Route path="/@:userId/:uid" element={<PostDetail/>}/>
                <Route path="/create" element={<CreatePost/>}/>
              </Routes>
            </div>
            <BottomMenu/>
          </div>
        </BrowserRouter>
      </PhoneWrap>
    </div>
  );
}

export default App;
