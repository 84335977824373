import { gql } from '@apollo/client';

export const myHoldingsQuery = gql`
  query myHoldings {
    myProfile {
      accountByType(type: primary) {
        holdings {
          tokenId
          balance
          created
          updated
          valuation
          post {
            id
            title
            status
            created
            updated
            attachments {
              id
              url
            }
            profile {
              id
              username
            }
          }
        }
      }
    }
  }
`;
