import * as React from 'react';
import { EventTypes } from '../types/EventTypes';
import { UploadIcon } from '@heroicons/react/outline';
import { useDispatch } from '../hooks/useDispatch';
import { useObservable } from 'rxjs-hooks';
import { AlertType, sendAlert } from '../store/globalAlertSubject';
import { useNavigate } from 'react-router-dom';
import { uploadAttachment } from '../store/requests/UploadAttachment';
import { createPost } from '../store/requests/CreatePost';
import {
  fileUploadSubject,
  loadingSubject,
  titleSubject
} from '../store/createPostSubjects';


export const CreatePost: React.FC = () => {
  const dispatch = useDispatch();
  const file = useObservable(() => fileUploadSubject)
  const title = useObservable(() => titleSubject)
  const isLoading = useObservable(() => loadingSubject, loadingSubject.getValue())
  const navigate = useNavigate();

  return (
    <div className={'mt-10 px-4 w-full overflow-auto pb-4'}>
      <form onSubmit={async (e) => {
        e.preventDefault()
        if (!file) {
          sendAlert({
            message: 'Please select a file',
            type: AlertType.Warning,
          })
          return
        }
        if (!title) {
          sendAlert({
            message: 'title is required',
            type: AlertType.Warning,
          })
          return
        }
        try {
          dispatch({type: EventTypes.createPostSubmitting})
          const attachment = await uploadAttachment(file)
          const response = await createPost(title, attachment.id)
          sendAlert({
            message: 'Post created',
            type: AlertType.Success,
          })
          navigate('/me')
        } catch (e) {
          sendAlert({
            message: `Error creating post: ${(e as any).message}`,
            type: AlertType.Error,
          })
        }
        dispatch({type: EventTypes.createPostSubmittingFinished})
      }}>
        <h1>Create Post</h1>
        <div className={'w-full'}>
          {/* tailwind image upload */}
          <div className={'flex items-center justify-center'}>
            <label htmlFor={'file-upload'} className={'cursor-pointer'}>
              <div className={'flex items-center justify-center'}>
                {file &&
                <img src={URL.createObjectURL(file)}
                     className={'h-auto w-full'}
                     alt={file.name}/>}
                {!file && <UploadIcon className={'w-8 h-8 text-gray-600'}/>}
              </div>
              {file && <div className={'text-gray-600'}>{file.name}</div>}
              {!file && <span className={'text-gray-600'}>Select Image</span>}
              <input
                className={'hidden'}
                id={'file-upload'}
                type={'file'}
                accept={'image/*'}
                multiple={false}
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (!file) console.warn('no file selected')
                  dispatch({
                    type: EventTypes.createPostFileUpload,
                    payload: file
                  });
                }}
              />
            </label>
          </div>
        </div>
        {/* tailwind input title */}
        <div className={'w-full mt-4'}>
          {/*<label htmlFor={'title'} className={'block text-gray-600'}>*/}
          {/*  Title*/}
          {/*</label>*/}
          <input
            id={'title'}
            className='w-full border border-gray-400 rounded-lg p-2'
            type={'text'}
            placeholder={'Title'}
            onChange={(e) => {
              dispatch({
                type: EventTypes.createPostTitleChanged,
                payload: e.target.value
              })
            }}
          />
        </div>
        {/* tailwind submit button */}
        <div className={'w-full mt-4'}>
          <button
            disabled={!file || !title || isLoading}
            type={'submit'}
            className={
              `w-full bg-blue-500 text-white rounded-lg p-2
                ${!file || !title ? 'opacity-50 cursor-not-allowed' : ''}
                `
            }
          >
            Create Post
          </button>
        </div>
      </form>
    </div>
  );
}
