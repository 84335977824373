import * as Rx from 'rxjs/operators'
import { BehaviorSubject, combineLatest, } from 'rxjs'
import { BaseEvent, baseEventSubject } from '../baseEventSubject'
import { EventTypes } from '../../types/EventTypes'
import { getUserId, login, logout } from './authRequests'
import { pickEvent } from '../utils/pickEvent'
import { pickEvents } from '../utils/pickEvents'

export interface ILoginData {
  username: string,
  password: string
}

interface IAuthState {
  loading: boolean,
  isLogin: boolean,
  userId?: string,
}

const initAuthState: IAuthState = {
  loading: false,
  isLogin: false,
  userId: undefined
}

const hasLoginSubject = new BehaviorSubject<boolean>(false)
const userIdSubject = new BehaviorSubject<string | undefined>(undefined)
const loadingSubject = new BehaviorSubject<boolean>(false)

export const localAuthSubject = new BehaviorSubject<IAuthState>(initAuthState)

baseEventSubject
  .pipe(
    pickEvent(EventTypes.login),
    Rx.mergeMap((event: BaseEvent<ILoginData>) => login(event.payload!)),
    Rx.mapTo(true),
  )
  .subscribe(hasLoginSubject)

baseEventSubject
  .pipe(
    pickEvent(EventTypes.logout),
    Rx.mergeMap(() => logout()),
    Rx.mapTo(false),
  )
  .subscribe(hasLoginSubject)

baseEventSubject
  .pipe(
    pickEvents([EventTypes.login, EventTypes.logout]),
    Rx.mapTo(true)
  )
  .subscribe(loadingSubject)

baseEventSubject
  .pipe(
    pickEvent(EventTypes.init),
    Rx.mergeMap(() => getUserId()),
    // @ts-ignore
  ).subscribe(userIdSubject)

hasLoginSubject
  .pipe(
    Rx.mapTo(false)
  )
  .subscribe(loadingSubject)

userIdSubject
  .pipe(
    Rx.map((userId) => !!userId)
  ).subscribe(hasLoginSubject)

// refresh userId
hasLoginSubject
  .pipe(
    Rx.distinctUntilChanged(),
    Rx.filter((hasLogin) => hasLogin),
    Rx.mergeMap(() => getUserId()),
    // @ts-ignore
  ).subscribe(userIdSubject)

combineLatest([loadingSubject, hasLoginSubject, userIdSubject])
  .pipe(
    Rx.map(([isLoading, hasLogin, userId]) => ({
      loading: isLoading,
      isLogin: hasLogin,
      userId: hasLogin ? userId : undefined
    })),
  )
  .subscribe(localAuthSubject)
