import * as React from 'react';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { AllPostsQuery } from '../store/gql/AllPosts.query';
import {
  AllPosts,
  AllPostsVariables
} from '../store/gql/__generated__/AllPosts';
import { getPostAttachmentUrls } from './utils/GetPostAttachmentUrls';
import { PostOrderBy } from '../types/GlobalTypes';
import Masonry from 'react-masonry-css';

export const PostLists: React.FC = () => {
  const [activatedTab, setActivatedTab] = useState<PostOrderBy>(PostOrderBy.capitalValue);
  const offset = 0;
  const limit = 10;
  const {
    loading,
    data,
    error,
    fetchMore,
    refetch
  } = useQuery<AllPosts, AllPostsVariables>(AllPostsQuery, {
    variables: {
      pagination: {
        offset,
        limit
      },
      orderBy: activatedTab
    }
  });

  useEffect(() => {
    refetch();
  }, [activatedTab]);

  if (error) return <p>Error :(</p>;
  if (loading || !data) return null;

  return (
    <div className={'pt-2 px-1 overflow-y-auto min-h-full'}>
      <div className="flex-shrink">
        <PostFilter activatedTab={activatedTab}
                    setActivatedTab={setActivatedTab}
        />
      </div>
      <Masonry
        breakpointCols={2}
        className="flex flex-row"
        columnClassName="p-1">
        {data.posts.edges.map(({ node: post }) => (
          <Link to={`/@${post.userId}/${post.uid}`}
                className={'card shadow-lg mt-4'} key={post.id}>
            {
              getPostAttachmentUrls(post).map(
                (url) => (
                  <figure key={url} className={''}>
                    <img src={url} alt={post.title}/>
                  </figure>
                )
              )
            }
            <div className="card-body p-4">
              <p>{post.title}</p>
              <p><small>{post.userId}</small></p>
              {post.latestTokenMarketData && <p>
                <span className="badge badge-outline mr-2">
                  ${post.latestTokenMarketData?.capitalValue.toFixed(0)} /
                  ${post.latestTokenMarketData?.unitPrice.toFixed(2)}
                </span>
              </p>}
            </div>
          </Link>
        ))}
      </Masonry>
      {data.posts.pageInfo.hasNextPage ? <div onClick={() => {
          fetchMore({
            variables: {
              pagination: {
                offset: offset + data?.posts.edges.length,
                limit
              }, orderBy: activatedTab
            }
          });
        }
        } className="flex justify-center btn btn-ghost">Load More</div> :
        <div className="flex justify-center">-- end --</div>}
    </div>
  );

};

const PostFilter: React.FC<{
  activatedTab: PostOrderBy,
  setActivatedTab: React.Dispatch<React.SetStateAction<PostOrderBy>>
}> = ({
        activatedTab,
        setActivatedTab
      }) => {
  return <div className="mt-6 mx-1">
    <div className="tabs tabs-boxed justify-center">
      <a onClick={() => setActivatedTab(PostOrderBy.all)}
         className={`tab ${activatedTab === PostOrderBy.all && 'tab-active'}`}>All</a>
      <a onClick={() => setActivatedTab(PostOrderBy.capitalValue)}
         className={`tab ${activatedTab === PostOrderBy.capitalValue && 'tab-active'}`}>Capital
        Value</a>
      <a onClick={() => setActivatedTab(PostOrderBy.tradeVolumeRecentDay)}
         className={`tab ${activatedTab === PostOrderBy.tradeVolumeRecentDay && 'tab-active'}`}>Day
        Trade Volume</a>
    </div>
  </div>;
};
