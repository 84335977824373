import axios from 'axios';
import { CONFIG } from '../../config';
import { ILoginData } from './localAuthSubject';
import { ICognitoLoginData } from './cognitoAuthSubject';

export const getUserId = () => axios.request<{ data?: { myProfile: { id: string } } }>(
  {
    method: 'POST',
    baseURL: CONFIG.baseURL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    url: '/api/graphql',
    data: { 'query': 'query {\n\tmyProfile {\n\t\tid\n\t\tdisplayName\n\t}\n}\n' }
  }
).then(function(response) {
  console.log(response.data);
  const userId = response.data?.data?.myProfile.id;
  return userId;
}).catch(function(error) {
  console.error(error);
});

export const login = (loginData: ILoginData) =>
  axios.request({
    method: 'POST',
    baseURL: CONFIG.baseURL,
    url: '/auth/local/login',
    headers: { 'Content-Type': 'application/json' },
    data: loginData,
    withCredentials: true
  });

export const logout = () => axios.request({
  method: 'GET',
  baseURL: CONFIG.baseURL,
  url: '/auth/logout',
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' }
}).then(cognitoLogout);


export const cognitoAuth = (loginData: ICognitoLoginData) =>
  axios.request({
    method: 'POST',
    baseURL: CONFIG.baseURL,
    url: '/auth/cognito/login',
    withCredentials: true,
    headers: {
      'Authorization': `Bearer ${loginData.jwtToken}`
    }
  });


export const cognitoLogout = ()=> window.location.replace(CONFIG.cognito.logout)
